@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

/* Not Found */
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(68, 68, 68);
  min-height: 80vh;
}
.not-found h2 {
  font-size: 55px;
}
.not-found p {
  font-size: 20px;
}

/* NavBar */

.nav-bar {
  height: 70px;
  background: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
}
.nav-bar a {
  text-decoration: none;
  color: white;
}
.nav-bar h2 {
  font-size: 40px;
}
.nav-bag {
  display: flex;
  align-items: center;
}
.bag-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: yellow;
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin-left: 5px;
}

/* Home */

.home-container {
  padding: 2rem 4rem;
}
.home-container h2 {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}
.products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 15px;
  width: 250px;
  max-width: 100%;
  height: 400px;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5),
    2px 2px 5px rgba(94, 104, 121, 0.3);
}
.product h3 {
  font-size: 25px;
  font-weight: 400;
}
.product img {
  width: 80%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.product .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product .details .price {
  font-size: 20px;
  font-weight: 700;
}
.product button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-top: 2rem;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
}

/* Cart */
.cart-container {
  padding: 2rem 4rem;
}
.cart-container h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
.cart-container .titles {
  margin: 2rem 0 1rem 0;
}
.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}
.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
  display: flex;
}
.cart-item .cart-product img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}
.cart-item .cart-product h3 {
  font-weight: 400;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover {
  color: black;
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}
.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}
.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: right;
  font-weight: 700;
}

/* cart summary */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}
.cart-summary .clear-btn {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout .cart-login {
  background-color: yellow;
  color: black;
}


.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}
.continue-shopping a,
.start-shopping a {
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}
.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*  Admin */
.link-active {
  color: #4b70e2;
}
.link-inactive {
  color: rgb(97, 97, 97);
}

/* Responsive */

@media (max-width: 665px) {
  /* NavBar */
  .nav-bar {
    padding: 0 1rem;
  }
  .nav-bar h2 {
    font-size: 30px;
  }

  /* Cart */
  .cart-container {
    padding: 2rem;
  }
  .cart-container .titles {
    display: none;
  }
  .cart-item,
  .cart-container .titles {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
  .cart-item .cart-product-total-price {
    justify-self: left;
  }
  .cart-summary {
    flex-direction: column;
  }
  .cart-summary .clear-btn {
    margin-bottom: 2rem;
  }
}

